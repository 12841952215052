import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

function HandlerExternalLink({ data }) {
    const [timer, setTimer] = useState(5)
    const params = useParams()

    const searchParams = new URLSearchParams(useLocation().search);
    const query = searchParams.get("u");


    useEffect(() => {
        // eslint-disable-next-line
    }, [params])

    useEffect(() => {
        const timerDownload = setTimeout(() => {
            if (timer > 1) {
                setTimer(timer - 1);
            } else {
                clearTimeout(timerDownload);
                window.open(query, "_blank");

                document.getElementById("status-handler").innerHTML = `Menyiapkan halaman ${query}...`
            }
        }, 1000);
        // Clear timeout if the component is unmounted
        return () => clearTimeout(timerDownload);
    })

    return (
        <div className="download-status__container">
            <div className="download-status__text-wrapper" id="status-handler">
                <a
                    href={query}
                    target="_blank" 
                    rel="noreferrer"
                    style={{ display: "none" }}
                    id="DLStart"
                > </a>
                Anda akan diarahkan ke halaman <span className="query">{query}</span> dalam {timer}...
            </div>
            <div className="download-status__text-wrapper">
                Klik&nbsp;
                {/* to prevent blank query */}
                <a href={// eslint-disable-next-line
                query || "javascript:void(0)"}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                        color: "var(--primary-red-color)",
                        fontWeight: "normal",
                        textDecoration: "underline"
                    }}>
                    di sini
                </a> jika jendela unduhan belum terbuka
            </div>
        </div>
    )
}

export default HandlerExternalLink