import React from "react";
import "./AffiliateItem.css";
// import { Link } from "react-router-dom";

function AffiliateItem({ affiliate, num }) {
  return (
    <>
      <div className="affiliate__item">
        <div className="affiliate__image-wrapper">
          <img className="affiliate__image" src={affiliate.image.url} data-src={affiliate.image.url} width={50} height={50} />
        </div>
        <div className="affiliate__desc-wrapper">
          <span className="affiliate_title">{affiliate.product_name}</span>
          <span>{affiliate.description}</span>
        </div>
        <a href={'https://jalantikus.com/handler/article?u='+affiliate.links.url}><button className="affiliate-button">Cek Website</button></a>
      </div>
    </>
  );
}

export default AffiliateItem;
