import React, { useState, useRef, useEffect } from 'react';
import "./Affiliate.css";
import AffiliateItem from "../Affiliate/AffiliateItem";

function Affiliate({ data, title }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const listRef = useRef(null);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const listElement = listRef.current;
    if (isExpanded) {
      listElement.style.maxHeight = `${listElement.scrollHeight}px`;
    } else {
      listElement.style.maxHeight = '300px';
    }
  }, [isExpanded]);

  const displayedData = isExpanded ? data : data.slice(0, 3);
  return (
    <>
      <div className="affiliate__container">
        <div className="affiliate__top-wrapper">
          <div className="affiliate__title">{title}</div>
          {data.length > 3 && (
            <button className="affiliate__expand-button" onClick={toggleExpand}>
              {isExpanded ? 'Perkecil' : 'Lainnya'}
            </button>
          )}
        </div>
        <div
          ref={listRef}
          className={`affiliate__list ${isExpanded ? 'expanded' : 'collapsed'}`}
        >
          {data &&
            displayedData.map((item, index) => (
              <AffiliateItem key={index} num={index + 1} affiliate={item} />
            ))}
        </div>

      </div>
    </>
  );
}

export default Affiliate;
